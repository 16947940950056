<template>
  <!-- 企业入驻 -->
  <div class="Entry_Enterprises">
    <div class="Enterprise_Information">
      <div class="Enterprise_Information_left"></div>
      <div class="Enterprise_Information_right">企业信息</div>
    </div>
    <div class="Enterprise_LoGo">
      <div class="Enterprise_LoGo_title">
        <span>＊</span>
        <span>企业/品牌LOGO</span>
        <el-upload class="Enterprise_LoGo_img" action="https://tsadmin.qudong.com/ApiUpload/upload_many"
          :headers="headers" :show-file-list="false" multiple name="file[]" :on-success="successAgreements"
          :before-upload="beforeRef">
          <img v-if="ObjList.logo" :src="ObjList.logo" class="Enterprise_LoGos">
          <i v-else class="el-icon-plus Enterprise_LoGo_img_icon"></i>
        </el-upload>
        <!-- <img
          :src="ObjList.logo"
          class="Enterprise_LoGo_img" alt=""> -->
      </div>
    </div>
    <div class="Enterprise_ipt">
      <span>＊</span>
      <span>企业简称</span>
      <input type="text" class="ipt1" placeholder="" v-model="ObjList.short_title">
      <span class="wancheng" @click="Complete1">完成</span>
    </div>
    <div class="border"></div>
    <!-- 账号信息 -->
    <div class="account_information">
      <div class="Enterprise_Information">
        <div class="Enterprise_Information_left"></div>
        <div class="Enterprise_Information_right">账号信息</div>
      </div>
    </div>
    <div class="Enterprise_LoGo">
      <div class="Enterprise_LoGo_title">
        <span>＊</span>
        <span>绑定手机号码</span>
        <input type="text" v-model="ObjList.legal_phone" disabled class="ipt1">
        <span class="Rebinding" @click="Rebinding">重新绑定</span>
      </div>
    </div>
    <!-- 弹窗1 -->
    <div class="tanchukuang_box" v-if="hidetan==false">
      <div class="tanchukuang">
        <div class="tanchukuang_top">
          <div>账号安全</div>
          <div @click="Close_Complaint">X</div>
        </div>
        <div class="tanchukuang_content">
          <div class="H_3">修改手机号</div>
          <input type="text" class="tanchuang_ipts" disabled v-model="ObjList.legal_phone">
          <div class="tanchuang_code">
            <input type="text" v-model="sendCodeone" class="code_ipt1">
            <button class="obtain_code" @click="sendCodes" :disabled="smsFlag1">{{sendTime1}}</button>
          </div>
        </div>
        <div class="tanchuang_Submit" @click="G_twotanchuang">下一步</div>
      </div>
    </div>
    <!-- 弹窗2 -->
    <div class="tanchukuang_box" v-if="hidetan1==false">
      <div class="tanchukuang">
        <div class="tanchukuang_top">
          <div>账号安全</div>
          <div @click="Close_Complaint1">X</div>
        </div>
        <div class="tanchukuang_content">
          <div class="H_3">修改手机号</div>
          <input type="text" class="tanchuang_ipts" v-model="new_phone" placeholder="新手机号">
          <div class="tanchuang_code">
            <input type="text" v-model="sendCodetwo" class="code_ipt1">
            <button class="obtain_code" @click="sendCodes1" :disabled="smsFlag2">{{sendTime2}}</button>
          </div>
        </div>
        <div class="tanchuang_Submit" @click="T_Submit">确认</div>
      </div>
    </div>
    <div class="border"></div>
    <!-- 企业资料 -->
    <div class="Enterprise_information">
      <div class="Enterprise_Information">
        <div class="Enterprise_Information_left"></div>
        <div class="Enterprise_Information_right">企业资料</div>
      </div>
      <div class="Enterprise_information_bottom">
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>公司名称</span>
          </div>
          <input type="text" v-model="ObjList.title" class="ipt2" placeholder="">
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>营业执照注册号</span>
          </div>
          <input type="text" v-model="ObjList.license_number" class="ipt2" placeholder="">
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>法人姓名</span>
          </div>
          <input type="text" v-model="ObjList.legal_person" class="ipt2" placeholder="">
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>官方网站</span>
          </div>
          <input type="text" v-model="ObjList.website" class="ipt2" placeholder="">
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>所属行业</span>
          </div>
          <el-select v-model="ObjList.hangye" @change="selectChange" placeholder="请选择">
            <el-option v-for="(item,index) in options" :key="index" :label="item.title" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>客服热线</span>
          </div>
          <input type="text" v-model="ObjList.customer" class="ipt2" placeholder="">
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>公司地址</span>
          </div>
          <input type="text" v-model="ObjList.address" class="ipt2" placeholder="">
        </div>
        <div class="Enterprise_information_1">
          <div class="title_left">
            <span>＊</span>
            <span>公司简介</span>
          </div>
          <textarea name="" id="" v-model="ObjList.one_info" class="textarea" cols="30" rows="10"></textarea>
        </div>
        <div class="border"></div>
      </div>
    </div>
    <!-- 附件上传 -->
    <div class="Enterprise_Information">
      <div class="Enterprise_Information_left"></div>
      <div class="Enterprise_Information_right">附件上传</div>
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left1">
        <span>＊</span>
        <span>法人身份证扫描件:</span>
      </div>

      <el-upload class="avatar-uploader_zheng" action="https://tsadmin.qudong.com/ApiUpload/upload_many"
        :headers="headers" :show-file-list="false" multiple name="file[]" :on-success="successAgreement"
        :before-upload="beforeRef">
        <img v-if="ObjList.id_card_just" :src="ObjList.id_card_just" class="avatar_zheng">
        <i v-else class="el-icon-plus avatar-uploader-icon_zheng"></i>
      </el-upload>
      <el-upload class="avatar-uploader_fan" action="https://tsadmin.qudong.com/ApiUpload/upload_many" :headers="headers"
        :show-file-list="false" multiple name="file[]" :on-success="successAgreement1" :before-upload="beforeRef">
        <img v-if="ObjList.id_card_bake" :src="ObjList.id_card_bake" class="avatar_fan">
        <i v-else class="el-icon-plus avatar-uploader-icon_fan"></i>
      </el-upload>
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left">
        <span>＊</span>
        <span>认证协议</span>
      </div>
      <el-upload class="avatar-uploader_xy" action="https://tsadmin.qudong.com/ApiUpload/upload_many" :headers="headers"
        :show-file-list="false" multiple name="file[]" :on-success="successAgreement2" :before-upload="beforeRef">
        <img v-if="ObjList.authentication_protocol" :src="ObjList.authentication_protocol" class="avatar_xy">
        <i v-else class="el-icon-plus avatar-uploader-icon_xy"></i>
      </el-upload>
      <a href="https://tsadmin.qudong.com/鹰眼投诉企业认证公函.docx" download="鹰眼投诉企业认证公函.docx"><span class="title_word_bgs">《认证协议》点击下载</span></a>
      <span class="title_word"
        style="margin-left:0;">模板，按要求填写后，扫描或拍照上传</span>
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left">
        <span>＊</span>
        <span>营业执照扫描照</span>
      </div>
      <el-upload class="avatar-uploader_xy" action="https://tsadmin.qudong.com/ApiUpload/upload_many" :headers="headers"
        :show-file-list="false" multiple name="file[]" :on-success="successAgreement3" :before-upload="beforeRef">
        <img v-if="ObjList.yingyezhizhao" :src="ObjList.yingyezhizhao" class="avatar_xy">
        <i v-else class="el-icon-plus avatar-uploader-icon_xy"></i>
      </el-upload>
      <div class="D_title_word">请上传营业执照的清晰彩色原件扫描或数码照，在有效期内且年检章齐全。大小不能超过2M。支持格式：.jpg、.png、.jpeg</div>
    </div>
    <div class="wancheng_box"><span class="wancheng" style="margin-top:20px;margin-right:50px;"
        @click="Complete2">完成</span></div>
    <div class="border"></div>
    <!-- 入驻联系人信息 -->
    <div class="Enterprise_Information">
      <div class="Enterprise_Information_left"></div>
      <div class="Enterprise_Information_right">入驻联系人信息</div>
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left">
        <span>＊</span>
        <span>联系人</span>
      </div>
      <input type="text" v-model="ObjList.name" class="ipt1">
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left">
        <span>＊</span>
        <span>联系人电话</span>
      </div>
      <input type="text" v-model="ObjList.phone" :disabled="isdisabled" class="ipt1">
      <span style="color:#1F72D9;margin-left:20px;cursor: pointer;" @click="ModifyPhone">修改手机号</span>
    </div>
    <div class="Enterprise_information_1" v-if="isSendCode ==true">
      <div class="title_left">
        <span>＊</span>
        <span>验证码</span>
      </div>
      <input type="text" v-model="sendCode2" class="code_ipt1">
      <button class="obtain_code" @click="sendCode" :disabled="smsFlag">{{sendTime}}</button>
    </div>
    <div class="Enterprise_information_1">
      <div class="title_left">
        <span>＊</span>
        <span>邮箱</span>
      </div>
      <input type="text" v-model="ObjList.emails" class="ipt1">
    </div>
    <div class="wancheng_box"><span class="wancheng" style="margin-top:20px;margin-right:50px;"
        @click="Complete3">完成</span></div>
    <div class="border"></div>
  </div>
</template>

<script>
import Axios from "axios";
import md5 from "js-md5";
import {
  HangYe_type,
  attestation_info,
  attestation_update,
  attestation_update_one,
  attestation_update_two,
  attestation_phone,
} from "@/api/api.js";
let token = localStorage.getItem("token");
export default {
   metaInfo(){
     return{
        title: '企业入驻',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      ObjList: "", //企业认领的详情
      headers: {
        token: token,
      }, //请求头传token
      index: "",
      options: [],
      liveUserID: "", //行业id
      liveUserName: "", //行业name
      imageUrl: "",
      sendTime: "获取验证码",
      snsMsgWait: 120,
      phone1: "", //绑定手机号
      smsFlag: false,
      sendCode1: "", //验证码输入框
      sendCode2: "", //验证码
      phone2: "", //联系人电话
      sendTime1: "获取验证码",
      snsMsgWait1: 120,
      smsFlag1: false,
      sendTime2: "获取验证码",
      snsMsgWait2: 120,
      smsFlag2: false,
      // sendTime3: "获取验证码",
      // snsMsgWait3: 120,
      // smsFlag3: false,
      Checkbox: false,
      hidetan: true, //弹窗
      hidetan1: true, //弹窗2
      isdisabled: true,
      isSendCode: false, //是否显示验证码
      sendCodeone: "", //弹出1的验证码
      new_phone: "", //弹出2的手机输入框
      sendCodetwo: "", //弹出2的验证码
    };
  },
  methods: {
    // 修改手机号
    ModifyPhone() {
      var that = this;
      that.isdisabled = !that.isdisabled;
      that.isSendCode = !that.isSendCode;
    },
    // 企业/品牌LOGO
    successAgreements(res) {
      var that = this;
      // //console.log(res.data[0]);
      that.ObjList.logo = res.data[0];
    },
    // 完成1
    Complete1() {
      var that = this;
      attestation_update({
        id: that.ObjList.id,
        logo: that.ObjList.logo, //企业logo
        brand_name: that.ObjList.short_title, //企业简称
      }).then((res) => {
        // //console.log(res);
        if (res.data.code == 200) {
          that.$message.success("修改成功");
        } else {
          that.$message.success("修改失败");
        }
      });
    },
    // 完成2
    Complete2() {
      var that = this;
      attestation_update_one({
        id: that.ObjList.id,
        title: that.ObjList.title, //公司名称
        license_number: that.ObjList.license_number, //营业执照注册号
        legal_person: that.ObjList.legal_person, //法人姓名
        website: that.ObjList.website, //官方网站
        industry_id: that.liveUserID, //行业id
        industry_name: that.ObjList.hangye, //品牌行业
        hangye: that.ObjList.hangye, //行业名称
        customer: that.ObjList.customer, //客服热线
        address: that.ObjList.address, //公司地址
        one_info: that.ObjList.one_info, //公司简介
        id_card_just: that.ObjList.id_card_just, //身份证正面
        id_card_bake: that.ObjList.id_card_bake, //身份证反面
        authentication_protocol: that.ObjList.authentication_protocol, //认证协议
        yingyezhizhao: that.ObjList.yingyezhizhao, //营业执照
        // 写到这里了
      }).then((res) => {
        // //console.log(res);
        if (res.data.code == 200) {
          that.$message.success("修改成功");
        }
      });
    },
    // 完成3
    Complete3() {
      var that = this;
      if (that.isSendCode == false) {
        attestation_update_two({
          id: that.ObjList.id,
          name: that.ObjList.name, //联系人
          phone: that.ObjList.phone, //联系电话
          emails: that.ObjList.emails, //联系邮箱
        }).then((res) => {
          //console.log(res);
          if (res.data.code == 200) {
            that.$message.success("修改成功");
          }
        });
      } else {
        Axios({
          method: "post",
          url: "https://weixin.qudong.com/api/user/check_code",
          params: {
            token: localStorage.getItem("token"),
            phone: that.ObjList.phone, //手机号
            code: that.sendCode2, //验证码
          },
        }).then((res) => {
          //console.log(res);
          if (res.data.code == 200) {
            attestation_update_two({
              id: that.ObjList.id,
              name: that.ObjList.name, //联系人
              phone: that.ObjList.phone, //联系电话
              emails: that.ObjList.emails, //联系邮箱
            }).then((res) => {
              //console.log(res);
              if (res.data.code == 200) {
                that.$message.success("修改成功");
              }
            });
          } else {
            that.$message.success(res.data.msg);
          }
        });
      }
    },
    // 请求图片成功
    successAgreement(index, res, file) {
      var that = this;
      //console.log(res.data[0]);
      //console.log(index);
      for (let i in that.lists) {
        if (i == index) {
          that.lists[i].logo = res.data[0];
        }
      }
    },
    // 请求前限制上传条件
    beforeRef(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG || !isLt2M) {
        this.$message.error("上传文件只能是图片格式，且大小不能超过2M");
      }
      return isJPG, isLt2M;
    },
    // 行业分类展示接口
    HangYe_type() {
      var that = this;
      HangYe_type({}).then((res) => {
        //console.log(res.data.data);
        that.options = res.data.data;
      });
    },
    // 下拉选取的id和name
    selectChange(e) {
      var that = this;
      //console.log(e);
      this.liveUserID = e.id;
      this.ObjList.hangye = e.title;
      //console.log(this.liveUserID, this.ObjList.hangye);
    },

    // 底部获取验证码
    sendCode() {
      var that = this;
      let phone1 = that.ObjList.phone;
      //console.log(that.phone1);
      let md_phone = phone1.substring(7);
      const myreg = /^[1][3-9][0-9]{9}$/;
      if (!myreg.test(phone1)) {
        this.$message.error("电话号码格式不对");
        return;
      }
      Axios({
        method: "post",
        url: "https://weixin.qudong.com/api/user/security_code",
        params: {
          phone_num: phone1,
          captcha: md5(md_phone),
        },
      }).then((res) => {
        //console.log(res);
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        var inter = setInterval(
          function () {
            (that.smsFlag = true),
              (that.sendTime = that.snsMsgWait + "s后重发"),
              (that.snsMsgWait = that.snsMsgWait - 1);
            if (that.snsMsgWait < 0) {
              clearInterval(inter);
              that.sendTime = "获取验证码";
              that.snsMsgWait = 120;
              that.smsFlag = false;
            }
          }.bind(this),
          1000
        );
      });
    },
    // 身份证正面图片
    successAgreement(res) {
      var that = this;
      //console.log(res.data[0]);
      that.ObjList.id_card_just = res.data[0];
    },
    // 身份证反面图片
    successAgreement1(res) {
      var that = this;
      //console.log(res.data[0]);
      that.ObjList.id_card_bake = res.data[0];
    },
    // 协议
    successAgreement2(res) {
      var that = this;
      //console.log(res.data[0]);
      that.ObjList.authentication_protocol = res.data[0];
    },
    // 营业执照扫描件
    successAgreement3(res) {
      var that = this;
      //console.log(res.data[0]);
      that.ObjList.yingyezhizhao = res.data[0];
    },
    // 获取验证码
    sendCodes() {
      var that = this;
      let phone2 = that.ObjList.legal_phone;
      //console.log(that.phone2);
      let md_phone = phone2.substring(7);
      const myreg = /^[1][3-9][0-9]{9}$/;
      if (!myreg.test(phone2)) {
        this.$message.error("电话号码格式不对");
        return;
      }
      Axios({
        method: "post",
        url: "https://weixin.qudong.com/api/user/security_code",
        params: {
          phone_num: phone2,
          captcha: md5(md_phone),
        },
      }).then((res) => {
        //console.log(res);
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        var inter = setInterval(
          function () {
            (that.smsFlag1 = true),
              (that.sendTime1 = that.snsMsgWait1 + "s后重发"),
              (that.snsMsgWait1 = that.snsMsgWait1 - 1);
            if (that.snsMsgWait1 < 0) {
              clearInterval(inter);
              that.sendTime1 = "获取验证码";
              that.snsMsgWait1 = 120;
              that.smsFlag1 = false;
            }
          }.bind(this),
          1000
        );
      });
    },
    // 获取验证码2
    sendCodes1() {
      var that = this;
      let phone2 = that.new_phone;
      //console.log(that.phone2);
      let md_phone = phone2.substring(7);
      const myreg = /^[1][3-9][0-9]{9}$/;
      if (!myreg.test(phone2)) {
        this.$message.error("电话号码格式不对");
        return;
      }
      Axios({
        method: "post",
        url: "https://weixin.qudong.com/api/user/security_code",
        params: {
          phone_num: phone2,
          captcha: md5(md_phone),
        },
      }).then((res) => {
        //console.log(res);
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        var inter = setInterval(
          function () {
            (that.smsFlag2 = true),
              (that.sendTime2 = that.snsMsgWait2 + "s后重发"),
              (that.snsMsgWait2 = that.snsMsgWait2 - 1);
            if (that.snsMsgWait2 < 0) {
              clearInterval(inter);
              that.sendTime2 = "获取验证码";
              that.snsMsgWait2 = 120;
              that.smsFlag2 = false;
            }
          }.bind(this),
          1000
        );
      });
    },
    // 手机换绑第二步
    T_Submit() {
      var that = this;
      Axios({
        method: "post",
        url: "https://weixin.qudong.com/api/user/check_code",
        params: {
          token: localStorage.getItem("token"),
          phone: that.new_phone,
          code: that.sendCodetwo,
        },
      }).then((res) => {
        //console.log(res);
        if (res.data.code == 200) {
            Axios({
        method: "post",
        url: "https://weixin.qudong.com/api/user/brand_bind_phone",
        params: {
          attestation_id:that.ObjList.id,
          token: localStorage.getItem("token"),
          phone: that.new_phone,
          code: that.sendCodetwo,
          decided: 0,
        },
      }).then((res) => {
        //console.log(res);
        if (res.data.code == 200) {
          that.$message.success(res.data.msg);
          setTimeout(
            "location.href='/EnterpriseCenter/AccountManagement'",
            1500
          );
        } else {
          that.$message.success(res.data.msg);
        }
      });
        } else {
          that.$message.success(res.data.msg);
        }
      });

      
    },
    // 弹窗1
    Rebinding() {
      this.hidetan = false;
    },
    Close_Complaint() {
      this.hidetan = true;
    },
    // 下一步
    G_twotanchuang() {
      var that = this;
      Axios({
        method: "post",
        url: "https://weixin.qudong.com/api/user/check_code",
        params: {
          token: localStorage.getItem("token"),
          phone: that.ObjList.legal_phone, //手机号
          code: that.sendCodeone, //验证码
        },
      }).then((res) => {
        //console.log(res);
        if (res.data.code == 200) {
          // that.$message.success(res.data.msg);
          setTimeout((that.hidetan1 = false), (that.hidetan = true), 1500);
        } else {
          that.$message.success(res.data.msg);
        }
      });

      //
      //
    },
    Close_Complaint1() {
      this.hidetan1 = true;
    },

    // 企业认证详情
    Attestation_info() {
      var that = this;
      let uid = localStorage.getItem("uid");
      attestation_info({ uid: uid }).then((res) => {
        console.log(res.data.data);
        that.ObjList = res.data.data;
      });
    },
  },

  mounted() {
    var that = this;
    that.HangYe_type();
    that.Attestation_info();
  },
};
</script>

<style scoped>
.Entry_Enterprises {
  width: 920px;
  background: #fff;
  margin: 0px auto;
  padding: 10px 20px 40px 20px;
}
.Enterprise_Information {
  display: flex;
  margin-top: 20px;
  align-items: center;
}
.Enterprise_Information_left {
  width: 4px;
  height: 22px;
  background: #1f72d9;
  margin-right: 10px;
}
.Enterprise_Information_right {
  color: #1f72d9;
  font-size: 22px;
  font-weight: bold;
}
.Enterprise_LoGo_title {
  font-size: 18px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.Enterprise_LoGo_title span:nth-child(1) {
  color: #ff4a4a;
}
.Enterprise_LoGo_title span:nth-child(2) {
  color: #333333;
}
.Enterprise_ipt {
  margin-top: 20px;
  margin-left: 30px;
}
.Enterprise_ipt span {
  font-size: 18px;
}
.Enterprise_ipt span:nth-child(1) {
  color: #ff4a4a;
}
.Enterprise_ipt span:nth-child(2) {
  color: #333333;
}
/* .Enterprise_LoGo_img {
  width: 57px;
  height: 57px;
  margin-left: 20px;
  border-radius: 50%;
} */
.ipt1 {
  padding-left: 15px;
  width: 328px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  margin-left: 15px;
}
.ipt2 {
  padding-left: 15px;
  width: 540px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  margin-left: 15px;
}
.border {
  margin-top: 25px;
  border-bottom: 1px solid #e8e8e8;
}

/* 账号信息 */
.account_information {
  margin-top: 20px;
}
.code_ipt {
  margin-left: 141px;
  margin-top: 20px;
  width: 150px;
  height: 40px;
  padding-left: 15px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
}
.obtain_code {
  margin-left: 20px;
  width: 124px;
  height: 40px;
  background: #1f72d9;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
}
/* 企业资料 */
.Enterprise_information_1 {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.title_left {
  width: 148px;
  text-align: right;
}
.title_left1 {
  width: 175px;
  text-align: right;
}
.title_left1 span {
  font-size: 18px;
}
.title_left1 span:nth-child(1) {
  color: #ff4a4a;
}
.title_left1 span:nth-child(2) {
  color: #333333;
}
.title_left span {
  font-size: 18px;
}
.title_left span:nth-child(1) {
  color: #ff4a4a;
}
.title_left span:nth-child(2) {
  color: #333333;
}
.title_word {
  margin-left: 20px;
  font-size: 16px;
  color: #999999;
}
.D_title_word {
  width: 530px;
  margin-left: 20px;
  font-size: 16px;
  color: #999999;
}
.title_word_bgs {
  color: #276cb5;
}
.Its_brands {
  display: flex;
  /* align-items: center; */
  margin-top: 20px;
}
.Its_brands_left {
  width: 148px;
  text-align: right;
  margin-top: 9px;
}
.Its_brands_left span {
  font-size: 18px;
}
.Its_brands_left span:nth-child(1) {
  color: #ff4a4a;
}
.Its_brands_left span:nth-child(2) {
  color: #333333;
}
.brands_name {
  margin-left: 20px;
}
.brands_name span {
  margin-right: 20px;
  font-size: 16px;
  color: #666666;
}
.brands_name .ipt_name {
  width: 312px;
  height: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.brands_name_top {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
>>> .el-select {
  width: 314px;
  height: 40px;
  border-radius: 4px;
  margin-left: 15px;
}

>>> .el-icon-plus:before {
  content: none;
}
.avatar-uploader {
  width: 102px;
  height: 102px;
  margin-left: 57px;
  background-image: url("../../assets/img/upload_img.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon_logo {
  font-size: 28px;
  color: #8c939d;
  width: 102px;
  height: 102px;
  line-height: 178px;
  text-align: center;
}
.avatar_logo {
  width: 102px;
  height: 102px;
  display: block;
  background: #fff;
}
.Add_information {
  display: flex;
  margin-left: 168px;
  align-items: center;
  width: 132px;
  margin-top: 10px;
  height: 40px;
  cursor: pointer;
  border: 1px solid #1f72d9;
  border-radius: 8px;
  color: #1f72d9;
}
.Add_information img {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-right: 10px;
}
.textarea {
  width: 540px;
  height: 110px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin-left: 15px;
  padding-left: 15px;
  padding-top: 10px;
  resize: none;
}
/* 正面 */
.avatar-uploader_zheng {
  width: 205px;
  height: 160px;
  border-radius: 6px;
  margin-left: 57px;
  background-image: url("../../assets/img/zhengmian_icon_id.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.avatar-uploader_zheng .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader_zheng .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon_zheng {
  font-size: 28px;
  color: #8c939d;
  width: 205px;
  height: 160px;
  line-height: 160px;
  border-radius: 6px;
  text-align: center;
}
.avatar_zheng {
  width: 205px;
  height: 160px;
  border-radius: 6px;
  display: block;
  background: #fff;
}
/* 反面 */
.avatar-uploader_fan {
  width: 205px;
  height: 160px;
  border-radius: 6px;
  margin-left: 57px;
  background-image: url("../../assets/img/fanmian_icon_id.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.avatar-uploader_fan .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader_fan .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon_fan {
  font-size: 28px;
  color: #8c939d;
  width: 205px;
  height: 160px;
  border-radius: 6px;
  line-height: 160px;
  text-align: center;
}
.avatar_fan {
  width: 205px;
  height: 160px;
  border-radius: 6px;
  display: block;
  background: #fff;
}
/* 认证协议 */
.avatar-uploader_xy {
  width: 120px;
  height: 124px;
  border-radius: 6px;
  margin-left: 57px;
  /* background-image: url("../../assets/img/xiangji.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.avatar-uploader_xy .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader_xy .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon_xy {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 124px;
  line-height: 124px;
  border-radius: 6px;
  text-align: center;
}
.avatar_xy {
  width: 120px;
  height: 124px;
  border-radius: 6px;
  display: block;
}
.code_ipt1 {
  margin-left: 15px;
  width: 150px;
  height: 40px;
  padding-left: 15px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
}

.checks {
  margin-top: 40px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 18px;
  margin-right: 10px;
  position: relative;
}
.checks span:nth-child(3) {
  color: #1f72d9;
}
.checks span:nth-child(4) {
  color: #1f72d9;
}
.Submit {
  margin-top: 30px;
  margin-left: 15px;
  width: 320px;
  height: 48px;
  background: #1f72d9;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
}
.wancheng {
  width: 88px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  color: #fff;
  cursor: pointer;
  height: 40px;
  background: #1f72d9;
  border-radius: 4px;
  margin-left: 250px;
}
.Rebinding {
  font-size: 16px;
  color: #1f72d9;
  margin-left: 20px;
  cursor: pointer;
}
.wancheng_box {
  display: flex;
  justify-content: flex-end;
}

/* 弹窗 */
.tanchukuang_box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 666;
  background: rgba(0, 0, 0, 0.4);
}
.tanchukuang {
  width: 600px;
  height: 400px;
  z-index: 666;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.tanchukuang_top {
  width: 560px;
  height: 56px;
  background: #1f72d9;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  color: #fff;
}
.tanchukuang_top div:nth-child(1) {
  font-size: 16px;
}
.tanchukuang_top div:nth-child(2) {
  font-size: 20px;
  cursor: pointer;
}
.tanchukuang_content {
  width: 550px;
  height: 200px;
  margin: 0 auto;
  margin-top: 30px;
  overflow-y: scroll !important;
  white-space: pre-wrap;
}
.tanchukuang_content .H_3 {
  margin-top: 20px;
  font-size: 20px;
  color: #333333;
}
.tanchuang_ipts {
  width: 336px;
  height: 40px;
  border: 1px solid #999999;
  border-radius: 5px;
  padding-left: 15px;
  margin-top: 20px;
}
.tanchuang_code {
  margin-top: 20px;
  display: flex;
}
.tanchuang_code .code_ipt1 {
  margin-left: 100px;
  width: 190px;
  height: 42px;
}
.tanchuang_Submit {
  width: 336px;
  height: 42px;
  background: #1f72d9;
  border-radius: 5px;
  margin: 0 auto;
  text-align: center;
  line-height: 42px;
  color: #fff;
  cursor: pointer;
}
.tanchukuang_bottom {
  width: 600px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px -2px 16px 0px rgba(3, 44, 95, 0.12);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tanchukuang_bottom_btn {
  width: 160px;
  height: 48px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  line-height: 48px;
  font-size: 20px;
  color: #333333;
  margin-right: 30px;
  cursor: pointer;
}
.tanchukuang_bottom_btn1 {
  width: 160px;
  height: 48px;
  background: #1f72d9;
  border-radius: 4px;
  color: #ffffff;
  line-height: 48px;
  cursor: pointer;
  font-size: 20px;
}
/* 企业/品牌 logo */
.Enterprise_LoGo_img {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  margin-left: 57px;
  background-image: url("../../assets/img/xiangji.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.Enterprise_LoGo_img .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.Enterprise_LoGo_img .el-upload:hover {
  border-color: #409eff;
}
.Enterprise_LoGo_img_icon {
  font-size: 28px;
  color: #8c939d;
  width: 57px;
  height: 57px;
  line-height: 160px;
  border-radius: 50%;
  text-align: center;
}
.Enterprise_LoGos {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  display: block;
  background: #fff;
}
</style>